const path = {
  home: '/',
  landingPage: '/home',
  login: '/login',
  registration: '/registration',
  forgot_password: '/forgot-password',
  recovery_password: '/recovery-password',
  meal_plan: '/meal-plan',
  add_meal_item: '/add-meal-item',
  weight_entry: '/weight-entry',
  analytics: '/analytics',
  resources: '/resources',
  my_profile: '/my-profile',
  subscribe: '/subscribe',
  about_us: '/about-us',
  balance_nutrition: '/balance_nutrition',
  physical_activity: '/physical_activity',
  terms_conditions: '/terms_&_conditions',
  support: '/support',
  my_memberships: '/my-memberships',
  error400: '/errors/error400',
  realistic_goals: '/realistic_goals',
  meal_planning: '/meal_planning',
  equilibrium_point: '/equilibrium_point',
  diet_guidelines: '/diet_guidelines',
  physical_activity_guide: '/physical_activity_guide',
  privacy_policy: "/privacy_policy",
  weight_management: "/weight_management",
  science_diet: "/science_diet"
};

export default path;
